import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { web3 } from "../../store/web3";
import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Approve, ApproveAddress } from "../../store/contract";

import "./index.css";
import { setLoader, getKybApproved, deleteKYB } from "../../store/actions/Auth";

class KYBApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kybApprovedData: [],
      publicAddress: "",
      id: "",
      kybStatus: "submitted",
    };
  }

  async componentDidMount() {
    await this.props.getKybApproved();
  }

  componentWillReceiveProps({ kybApprovedData }) {
    this.setState({ kybApprovedData });
  }

  handleEditChange = (e) => this.setState({ kybStatus: e.target.value });

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");

  deleteKYB = async (publicAddress, id) => {
    try {
      let address = (await web3.currentProvider.enable())[0];

      if (publicAddress == "") {
        EventBus.publish("error", `Please select the address`);
        return;
      }

      let isSuperAdmin = await (Approve.methods.isSuperAdmin(address).call({ from: address }));

      if (isSuperAdmin == true) {
        this.props.setLoader({
          message: "Delete in Progress...",
          status: true,
        });

        await web3.eth
          .sendTransaction({
            from: address,
            value: 0,
            to: ApproveAddress,
            gas: 2000000,
            data: Approve.methods
              .removeWhitelist(publicAddress)
              .encodeABI(),
          })
          .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
          .on("receipt", async (receipt) => {
            this.props.setLoader({ status: false });
            this.props.deleteKYB({ _id: id });
          });
      } else {
        EventBus.publish("error", `Please approve through Super Admin Address!`);
        return;
      }
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Execution Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute`);
    }
  };


  render() {
    let { kybApprovedData, kybStatus } = this.state;

    const columns = [
      {
        id: "verify",
        Header: "Verify",
        accessor: (kybApprovedData) =>
          kybApprovedData["kybStatus"] == "approved" && (
            <Button
              variant="outlined"
              className="mx-1 view-btn verifyBtn"
              onClick={() => this.deleteKYB(kybApprovedData["publicAddress"], kybApprovedData['_id'])}
            >
              Delete
            </Button>
          ),
        filterable: false,
      },
      {
        id: "publicAddress",
        Header: "Wallet Address",
        accessor: (kybApprovedData) =>
          kybApprovedData["publicAddress"] ? (
            <CopyToClipboard
              text={kybApprovedData["publicAddress"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kybApprovedData[
                "publicAddress"
              ].substring(0, 8) +
                "....." +
                kybApprovedData["publicAddress"].substring(
                  34,
                  kybApprovedData["publicAddress"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
          filterable: false,
      },
      {
        id: "Name",
        Header: "Business Name",
        accessor: (kybApprovedData) => (kybApprovedData["title"] ? kybApprovedData["title"] : "-"),
      },
      {
        id: "email",
        Header: "Email",
        accessor: (kybApprovedData) =>
          kybApprovedData["email"] ? (
            <CopyToClipboard
              text={kybApprovedData["email"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kybApprovedData["email"].substring(0, 8) + "....."}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
      },
      {
        id: "ein",
        Header: "Tax Id",
        accessor: (kybApprovedData) => (kybApprovedData["ein"] ? kybApprovedData["ein"] : "-"),
      },
      {
        id: "ownerSocialNo",
        Header: "Social Security",
        accessor: (kybApprovedData) =>
          kybApprovedData["ownerSocialNo"] ? kybApprovedData["ownerSocialNo"] : "-",
      },
      {
        id: "document",
        Header: "Business Document",
        accessor: (kybApprovedData) =>
          kybApprovedData["document"] ? (
            <a href={kybApprovedData["document"]} target="_blank">{kybApprovedData["document"]}</a>
          ) : "-",
      },
      {
        id: "website",
        Header: "Website",
        accessor: (kybApprovedData) =>
          kybApprovedData["website"] ? (
            <a href={kybApprovedData["website"]} target="_blank">{kybApprovedData["website"]}</a>
          ) : "-",
      },
      {
        id: "linkedin",
        Header: "LinkedIn",
        accessor: (kybApprovedData) =>
          kybApprovedData["linkedin"] ? (
            <a href={kybApprovedData["linkedin"]} target="_blank">{kybApprovedData["linkedin"]}</a>
          ) : "-",
      },
      {
        id: "facebook",
        Header: "Facebook (Meta)",
        accessor: (kybApprovedData) =>
          kybApprovedData["facebook"] ? (
            <a href={kybApprovedData["facebook"]} target="_blank">{kybApprovedData["facebook"]}</a>
          ) : "-",
      },
      {
        id: "twitter",
        Header: "Twitter (X)",
        accessor: (kybApprovedData) =>
          kybApprovedData["twitter"] ? (
            <a href={kybApprovedData["twitter"]} target="_blank">{kybApprovedData["twitter"]}</a>
          ) : "-",
      },
    ];

    console.log("************kybApprovedData", kybApprovedData);
    // console.log("************kybStatus", kybStatus);

    return (
      <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
            <div className="mb-3">
              <h1>Pending KYN</h1>
            </div>
            <Fragment>
              <div className="main-container-head mb-3">
                <ReactTable
                  className="table"
                  data={this.state.kybApprovedData}
                  resolveData={(kybApprovedData) => kybApprovedData.map((row) => row)}
                  columns={columns}
                  minRows={20}
                  filterable={true}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getKybApproved,
  setLoader,
  deleteKYB,
};

const mapStateToProps = ({ Auth }) => {
  let { kybApprovedData, isStatusModal } = Auth;
  return { kybApprovedData, isStatusModal };
};
export default connect(mapStateToProps, mapDispatchToProps)(KYBApproved);
