import Approve from "./views/Approve/index.js";
import Blacklist from "./views/Blacklist/index.js";
// import ApproveKYC from "./views/ApproveKYC/index.js";
import ApproveKYB from "./views/ApproveKYB/index.js";
// import ApprovedKYC from "./views/ApprovedKYC/index.js";
import KYBApproved from "./views/KYBApproved/index.js";
import AddSuperAdmin from "./views/AddSuperAdmin/index.js";
import Airdrop from "./views/Airdrop/index.js";
import CreateNft from "./views/CreateNft/index.js";
import Profile from "./views/Profile/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: Approve,
    hidden: true,
  },
  // {
  //   layout: "/home",
  //   path: "/KycApprove",
  //   name: "Approve KYC",
  //   component: ApproveKYC,
  //   imgUrl: "/images/approved.svg",
  // },
  // {
  //   layout: "/home",
  //   path: "/KycApproved",
  //   name: "Approved KYC",
  //   component: ApproveKYC,
  //   imgUrl: "/images/approved.svg",
  // },
  // {
  //   layout: "/home",
  //   path: "/KynApprove",
  //   name: "Approve KYN",
  //   component: ApproveKYB,
  //   imgUrl: "/images/approved.svg",
  // },
  // {
  //   layout: "/home",
  //   path: "/KYBApproved",
  //   name: "Approved KYN",
  //   component: KYBApproved,
  //   imgUrl: "/images/approved.svg",
  // },
  {
    layout: "/home",
    path: "/Approve",
    name: "Whitelist",
    component: Approve,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/Blacklist",
    name: "Blacklist",
    component: Blacklist,
    imgUrl: "/images/blacklist.svg",
  },
  {
    layout: "/home",
    path: "/AddSuperAdmin",
    name: "Add Super Admin",
    component: AddSuperAdmin,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/CreateNft",
    name: "Create NFT",
    component: CreateNft,
    imgUrl: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/Airdrop",
    name: "Airdrop",
    component: Airdrop,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/profile",
    name: "Profile",
    component: Profile,
    imgUrl: "/images/details.svg",
  },
];

export default routes;