/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
      type: 'LOGIN',
      payload: data,
    });

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});


/*========== KYC ACTIONS ============= */

export const getKYC = () => ({
  type: 'GET_KYC'
});

export const setKYC = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const getKycApproved = () => ({
  type: 'GET_KYC_APPROVED'
});

export const setKycApproved = (data) => ({
  type: 'SET_KYC_APPROVED',
  payload: data
});

export const deleteKYC = (data) => ({
  type: 'DELETE_KYC',
  payload: data
});

export const updateKYC = (data) => ({
  type: 'UPDATE_KYC',
  payload: data
});


/*========== KYB ACTIONS ============= */

export const getKYB = () => ({
  type: 'GET_KYB'
});

export const setKYB = (data) => ({
  type: 'SET_KYB',
  payload: data
});

export const getKybApproved = () => ({
  type: 'GET_KYB_APPROVED'
});

export const setKybApproved = (data) => ({
  type: 'SET_KYB_APPROVED',
  payload: data
});

export const deleteKYB = (data) => ({
  type: 'DELETE_KYB',
  payload: data
});

export const updateKYB = (data) => ({
  type: 'UPDATE_KYB',
  payload: data
});


/*========== MODAL ACTIONS ============= */

export const toggleStatusModal = (data) => ({
  type: 'TOGGLE_STATUS_MODAL',
  payload: data
});

/*========== MARKETPLACE ACTIONS ============= */

export const getAllDonations = () => ({
  type: 'GET_ALL_DONATIONS',
});

export const setAllDonations = (data) => ({
  type: 'SET_ALL_DONATIONS',
  payload: data
});

export const setDonationsNames = (data) => ({
  type: 'SET_DONATIONS_NAMES',
  payload: data
});

export const getUser = (data) => ({
  type: 'GET_USER',
  payload: data
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data
});

export const getUriData = (data) => ({
  type: 'GET_URI_DATA',
  payload: data,
});

export const setUriData = (data) => ({
  type: 'SET_URI_DATA',
  payload: data,
});