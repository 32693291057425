import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { web3 } from "../../store/web3";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Approve, ApproveAddress } from "../../store/contract";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "./index.css";
import { setLoader, updateKYB, getKYB, deleteKYB, toggleStatusModal } from "../../store/actions/Auth";

class ApproveKYB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kybData: [],
      publicAddress: "",
      id: "",
      kybStatus: "submitted",
    };
  }

  async componentDidMount() {
    await this.props.getKYB();
  }

  componentWillReceiveProps({ kybData }) {
    this.setState({ kybData });
  }

  handleEditChange = (e) => this.setState({ kybStatus: e.target.value });

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");

  verifyKYB = (publicAddress, open, id) => {
    this.setState({ publicAddress, id });
    this.props.toggleStatusModal(open);
  };

  submitApproval = async () => {
    try {
      let { id, kybStatus, publicAddress } = this.state;

      let address = (await web3.currentProvider.enable())[0];

      if (publicAddress == "") {
        EventBus.publish("error", `Please select the address`);
        return;
      }

      let isSuperAdmin = await (Approve.methods.isSuperAdmin(address).call({ from: address }));

      if (isSuperAdmin == true) {
        if (kybStatus == "approved") {
          let isApprove = await (Approve.methods.isWhitelist(publicAddress).call({ from: address }));
          if (isApprove == true) {
            EventBus.publish("error", `Address already approved`);
            return;
          } else {
            this.props.toggleStatusModal(false);
            this.props.setLoader({
              message: "Approval in Progress...",
              status: true,
            });

            await web3.eth
              .sendTransaction({
                from: address,
                value: 0,
                to: ApproveAddress,
                gas: 2000000,
                data: Approve.methods
                  .addWhitelist(publicAddress)
                  .encodeABI(),
              })
              .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
              .on("receipt", async (receipt) => {
                this.props.updateKYB({ kybStatus, publicAddress });
                this.props.setLoader({ status: false });
              });
          }
        } else if (kybStatus == "discard") {
          let isApprove = await (Approve.methods.isWhitelist(publicAddress).call({ from: address }));
          if (isApprove == false) {
            EventBus.publish("error", `Address is not approved`);
            return;
          } else {
            this.props.toggleStatusModal(false);
            this.props.setLoader({
              message: "Blacklist in Progress...",
              status: true,
            });

            await web3.eth
              .sendTransaction({
                from: address,
                value: 0,
                to: ApproveAddress,
                gas: 2000000,
                data: Approve.methods
                  .removeWhitelist(publicAddress)
                  .encodeABI(),
              })
              .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
              .on("receipt", async (receipt) => {
                this.props.setLoader({ status: false });
                this.props.deleteKYB({ _id: id });
              });
          }
        }
      } else {
        EventBus.publish("error", `Please approve through Super Admin Address!`);
        return;
      }
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Execution Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute`);
    }
  };

  render() {
    let { isStatusModal } = this.props;
    let { kybData, kybStatus } = this.state;

    const columns = [
      {
        id: "verify",
        Header: "Verify",
        accessor: (kybData) =>
          kybData["kybStatus"] == "submitted" && (
            <Button
              variant="outlined"
              className="mx-1 view-btn verifyBtn"
              onClick={() => this.verifyKYB(kybData["publicAddress"], true, kybData['_id'])}
            >
              Verify
            </Button>
          ),
        filterable: false,
      },
      {
        id: "publicAddress",
        Header: "Wallet Address",
        accessor: (kybData) =>
          kybData["publicAddress"] ? (
            <CopyToClipboard
              text={kybData["publicAddress"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kybData[
                "publicAddress"
              ].substring(0, 8) +
                "....." +
                kybData["publicAddress"].substring(
                  34,
                  kybData["publicAddress"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
        filterable: false,
      },
      {
        id: "Name",
        Header: "Business Name",
        accessor: (kybData) => (kybData["title"] ? kybData["title"] : "-"),
      },
      {
        id: "email",
        Header: "Email",
        accessor: (kybData) =>
          kybData["email"] ? (
            <CopyToClipboard
              text={kybData["email"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kybData["email"].substring(0, 8) + "....."}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
      },
      {
        id: "ein",
        Header: "Tax Id",
        accessor: (kybData) => (kybData["ein"] ? kybData["ein"] : "-"),
      },
      {
        id: "ownerSocialNo",
        Header: "Social Security",
        accessor: (kybData) =>
          kybData["ownerSocialNo"] ? kybData["ownerSocialNo"] : "-",
      },
      {
        id: "document",
        Header: "Business Document",
        accessor: (kybData) =>
          kybData["document"] ? (
            <a href={kybData["document"]} target="_blank">{kybData["document"]}</a>
          ) : "-",
      },
      {
        id: "website",
        Header: "Website",
        accessor: (kybData) =>
          kybData["website"] ? (
            <a href={kybData["website"]} target="_blank">{kybData["website"]}</a>
          ) : "-",
      },
      {
        id: "linkedin",
        Header: "LinkedIn",
        accessor: (kybData) =>
          kybData["linkedin"] ? (
            <a href={kybData["linkedin"]} target="_blank">{kybData["linkedin"]}</a>
          ) : "-",
      },
      {
        id: "facebook",
        Header: "Facebook (Meta)",
        accessor: (kybData) =>
          kybData["facebook"] ? (
            <a href={kybData["facebook"]} target="_blank">{kybData["facebook"]}</a>
          ) : "-",
      },
      {
        id: "twitter",
        Header: "Twitter (X)",
        accessor: (kybData) =>
          kybData["twitter"] ? (
            <a href={kybData["twitter"]} target="_blank">{kybData["twitter"]}</a>
          ) : "-",
      },
    ];

    // console.log("************kybData", kybData);
    // console.log("************kybStatus", kybStatus);

    return (
      <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
            <div className="mb-3">
              <h1>Pending KYN</h1>
              {/* <Button variant="outlined" className="add-btn" onClick={}>Approve Drop</Button> */}
            </div>
            <Fragment>
              <div className="main-container-head mb-3">
                <ReactTable
                  className="table"
                  data={this.state.kybData}
                  resolveData={(kybData) => kybData.map((row) => row)}
                  columns={columns}
                  minRows={20}
                  filterable={true}
                />
              </div>
            </Fragment>
          </div>
        </div>
        {/* ---------------VIEW MODAL--------------- */}

        <Modal
          isOpen={isStatusModal}
          toggle={() => this.props.toggleStatusModal(false)}
          className="main-modal drop-modal"
        >
          <ModalHeader toggle={() => this.props.toggleStatusModal(false)}>
            <div className="modal-logo">
              <img
                src={require("../../assets/img/greencrosslogo.png")}
                alt="modal-logo"
              />
            </div>
            <div className="drop-modal-title text-center">
              <p className="">Approve KYN</p>
            </div>
            <div className="drop-modal-line">
              <hr />
            </div>
          </ModalHeader>
          <ModalBody className="modal-body drop-modal-body">
            <div className="row">
              <div className="col-12">
                <ValidatorForm onSubmit={this.submitApproval}>
                  <TextValidator
                    select
                    native
                    fullWidth
                    type="boolean"
                    name="kybStatus"
                    value={kybStatus}
                    variant="outlined"
                    id="standard-full-width"
                    className="form-input-field"
                    onChange={this.handleEditChange}
                    label={
                      <label>
                        Approval Status <span>*</span>
                      </label>
                    }
                  >
                    <MenuItem value="approved">Approve</MenuItem>
                    <MenuItem value="discard">Discard</MenuItem>
                  </TextValidator>
                </ValidatorForm>
              </div>
              <div className="col-12 mt-5 d-flex justify-content-around">
                <Button
                  className="cancel-btn col-4"
                  type="button"
                  onClick={() => this.props.toggleStatusModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="add-btn col-4"
                  type="button"
                  onClick={this.submitApproval}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getKYB,
  setLoader,
  deleteKYB,
  updateKYB,
  toggleStatusModal,
};

const mapStateToProps = ({ Auth }) => {
  let { kybData, isStatusModal } = Auth;
  return { kybData, isStatusModal };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveKYB);
