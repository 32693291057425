import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  isLogin: false,
  auth: localStorage.getItem("token"),
  address: localStorage.getItem("token"),
  isLoader: { message: "Please Wait...", status: false },
  registerData: [],
  isStatusModal: false,
  kycData: [],
  kybData: [],
  kybApprovedData: [],
  kycApprovedData: [],
  userData: [],
  donationContracts: [],
  donationNames: [],
  uriData: [],
};


const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "LOGIN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        address: payload,
        auth: payload,
        isLogin: !state.isLogin,
      };

    case "IS_LOGIN_DISABLED":
      return {
        ...state,
      };

    case "LOGOUT":
      setToken();
      localStorage.removeItem("token");
      return {
        ...state,
        address: "",
        auth: "",
        isLogin: !state.isLogin,
      };

    case "SET_ADDRESS":
      return {
        ...state,
        address: payload,
      };

    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    /*========== KYC REDUCERS ============= */

    case "SET_KYC":
      return {
        ...state,
        kycData: payload,
      };

    case "SET_KYC_APPROVED":
      return {
        ...state,
        kycApprovedData: payload,
      };

    /*========== KYB REDUCERS ============= */

    case "SET_KYB":
      return {
        ...state,
        kybData: payload,
      };

    case "SET_KYB_APPROVED":
      return {
        ...state,
        kybApprovedData: payload,
      };


    /*========== MARKETPLACE REDUCERS ============= */
    case "SET_ALL_DONATIONS":
      return {
        ...state,
        donationContracts: payload,
      };

    case "SET_DONATIONS_NAMES":
      return {
        ...state,
        donationNames: payload,
      };

    case "SET_USER":
      return {
        ...state,
        userData: payload,
      };

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };


    /*========== MODAL REDUCERS ============= */
    case "TOGGLE_STATUS_MODAL":
      return {
        ...state,
        isStatusModal: payload,
      };

    default:
      return state;
  }
};

export default Auth;
